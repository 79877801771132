import { IPaginatedResponse, IResponse } from "interfaces/general/response.interface";

import { BaseApi, Payload } from "./base";
import { IProductionPlanPair } from "interfaces/production-plans.interface";

export default new (class ProductionPlans extends BaseApi {
	async getAllProductionPlans(
		payload: Payload,
		abortController = new AbortController()
	): Promise<IPaginatedResponse<IProductionPlanPair>> {
		return await this.doRequest(`/plans`, payload, "GET", {
			signal: abortController.signal,
		});
	}

	async getSpecificProductionPlan(
		payload: Payload,
		abortController = new AbortController()
	) {
		console.log("getSpecificProductionPlan in APIClient... ", payload);
		return await this.doRequest(
			`/plans/${payload.correlationId}`,
			payload,
			"GET",
			{ signal: abortController.signal }
		);
	}

	async deleteSpecifcProductionPlan(payload: Payload) {
		return await this.doRequest(
			`/plans/${payload.correlationId}`,
			payload,
			"DELETE"
		);
	}

	async deleteManyProductionPlans(payload: Payload) {
		return await this.doRequest(`/plans`, payload, "DELETE");
	}

	async editProductionPlan(
		payload: Payload & {
			correlationId: string;
			updatedItemIds?: [string];
			isUpdatingActual: boolean;
			data: any;
		}
	) {
		return await this.doRequest(
			`/plans/${payload.correlationId}`,
			payload,
			"PUT"
		);
	}

	async editProductionPlanActualQuantity(
		payload: Payload & {
			correlationId: string;
			updatedItemIds?: [string];
			isUpdatingActual: boolean;
			data: any;
		}
	) {
		return await this.doRequest(
			`/plans/${payload.correlationId}`,
			payload,
			"PUT"
		);
	}

	async addProductionPlan(payload: Payload) {
		return await this.doRequest(`/plans`, payload, "POST");
	}

	async validateProductionPlan(payload: Payload) {
		return await this.doRequest(`/plans-validate`, payload, "POST");
	}

	async createBulkProductionPlans(payload: Payload) {
		return await this.doRequest(`/plans-bulk`, payload, "POST");
	}

	async getAllPlansRawMaterials(
		payload: Payload,
		abortController = new AbortController()
	) {
		return await this.doRequest(`/plans-raw-materials`, payload, "GET", {
			signal: abortController.signal,
		});
	}

	async updateActualProductionPlanQuantity(payload: Payload) {
		return await this.doRequest(
			`/plans-actual-quantity-update`,
			payload,
			"POST"
		);
	}

	async getDependentProductionPlansByRecipeId(payload: Payload) {
		return await this.doRequest<IPaginatedResponse<IProductionPlanPair>>(
			`/plans/recipes/id/${payload.recipeId}`,
			payload,
			"GET"
		);
	}

	async getSuggestedProductionPlans(payload: Payload) {
		return await this.doRequest(`/plans/suggested`, payload, "POST");
	}

	async getProductionPlanRequirementsGraph(payload: Payload) {
		return await this.doRequest(
			`/plans/${payload.planId}/requirements-graph`,
			payload,
			"GET"
		);
	}

	async moveProductionPlanItems(
		payload: Payload & {
			planId: string;
			itemsToBeMoved: string[];
			newPlans: string[];
		}
	) {
		return await this.doRequest(
			`/plans/${payload.planId}/move-items`,
			payload,
			"POST"
		);
	}
})();
